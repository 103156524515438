// node-modules
import objectFitImages from 'object-fit-images';
import svg4everybody from 'svg4everybody';
import { TweenLite, Power1 } from 'gsap/TweenMax';
import { ScrollToPlugin } from 'gsap/all';

// polyfills
import 'dom4';

// default components
import '@/components/lazy-load';
import '@/components/preloader';
import '@/components/footer';
import '@/components/scrollToTop';
import '@/components/header';
import '@/components/animations';
import '@/components/button';
import '@/components/modals';
import '@/components/breadcrumbs';

// functional components
import { sayHello } from '@/scripts/utils/sayHello';
import { scrollDownInit } from '@/components/scrollDown';
import { smallSlider, newsSlider, membershipsSlider } from '@/components/swiperSlider';
import { Form } from '@/components/Form';
import { initGoogleMaps } from '@/components/googleMaps';
import { Tabs } from '@/components/tabs';
import { menuToggle } from '@/components/hamburgerMenu';
import { Dropdown } from '@/components/Dropdown';
import { customSelect } from '@/components/customSelect';

// assets
import 'styles/main.scss';


// eslint-disable-next-line
const plugins = [ScrollToPlugin];

TweenLite.defaultEase = Power1.easeOut;


document.addEventListener('DOMContentLoaded', () => {
  objectFitImages();
  svg4everybody();
  sayHello();
  // init scroll-down button
  scrollDownInit();
  // init menu
  menuToggle();

  // init sliders
  document.querySelectorAll('[data-role="slider-simple"]')
    .forEach(container => smallSlider(container));
  document.querySelectorAll('[data-role="slider-news"]')
    .forEach(container => newsSlider(container));
  document.querySelectorAll('[data-role="slider-memberships"]')
    .forEach(container => membershipsSlider(container));
  // init form
  document.querySelectorAll('[data-role="contact-form"]')
    .forEach(element => new Form(element));
  // init google maps
  document.querySelectorAll('[data-role="google-maps-container"]')
    .forEach(el => initGoogleMaps(el));
  // init tabs
  document.querySelectorAll('[data-role="tab-list-container"]')
    .forEach(el => new Tabs({
      mainContainer: el,
    }));
  // init dropdown
  document.querySelectorAll('[data-role*="dropdown-container"]')
    .forEach(element => new Dropdown({ element }));
  // selects
  document.querySelectorAll('[data-custom-select]')
    .forEach(el => customSelect(el));
  // init counter
});
