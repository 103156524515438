// node-modules

import GoogleMapsLoader from 'google-maps';

// other
import { isIe } from '../../scripts/utils/detect-ie';
import mapStyles from './styles.json';

// assets
import './googleMaps.scss';

function getPoint(coords, google) {
  try {
    const { lat, lng } = JSON.parse(coords);
    return new google.maps.LatLng(lat, lng);
  } catch (e) {
    return false;
  }
}

function addMarker(google, position, icon, origin, map, restOptions = {}) {
  const marker = new google.maps.Marker({
    position,
    icon: isIe() ? null : icon,
    optimized: false,
    origin: isIe() ? null : new google.maps.Point(...origin.split(' ')),
    ...restOptions,
  });
  marker.setMap(map);
  return marker;
}

export function initGoogleMaps(el) {
  const {
    key,
    options,
    coords,
    center,
    markerImage,
    markerOrigin = '10 14',
    // nearby = '[]',
  } = el.dataset;
  GoogleMapsLoader.KEY = key;
  GoogleMapsLoader.VERSION = 'weekly';
  return GoogleMapsLoader.load((google) => {
    const markerPosition = getPoint(coords, google);
    const centerPosition = getPoint(center, google) || markerPosition;
    const map = new google.maps.Map(el, {
      center: centerPosition,
      styles: mapStyles,
      ...JSON.parse(options),
    });
    addMarker(
      google,
      markerPosition,
      markerImage,
      markerOrigin,
      map,
    );
  });
}
