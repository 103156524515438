// node-modules
import { TweenLite } from 'gsap/TweenLite';


// assets
import './scrollDown.scss';

export function scrollDownInit() {
  const scrollDown = document.querySelectorAll('[data-role="button-scroll"]');

  function getElem(item) {
    item.addEventListener('click', (event) => {
      event.preventDefault();
      const id = item.hash;
      const nextSection = document.querySelector(id);
      const scrollSection = nextSection.offsetTop;
      TweenLite.to(window, 0.8, { scrollTo: { y: scrollSection, autoKill: false } });
    });
  }

  if (scrollDown) {
    scrollDown.forEach(getElem);
  }
}
