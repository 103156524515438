// node-modules
import * as Swiper from 'swiper/dist/js/swiper';

// assets
import 'swiper/dist/css/swiper.min.css';
import './swiperSlider.scss';

const sliderOptions = {
  spaceBetween: 0,
  speed: 1000,
  lazy: {
    loadPrevNext: true,
    loadPrevNextAmount: 2,
  },
  keyboard: true,
};

export function smallSlider(sliderContainer) {
  return new Swiper(sliderContainer.querySelector('[data-role="slider-container"]'), {
    ...sliderOptions,
    slidesPerView: 1,
    effect: 'fade',
    autoplay: {
      delay: 3000,
    },
    navigation: {
      nextEl: sliderContainer.querySelector('[data-role="slider-next"]'),
      prevEl: sliderContainer.querySelector('[data-role="slider-prev"]'),
    },
  });
}

export function newsSlider(sliderContainer) {
  return new Swiper(sliderContainer.querySelector('[data-role="slider-container"]'), {
    ...sliderOptions,
    slidesPerView: 3,
    spaceBetween: 60,
    effect: 'slide',
    breakpoints: {
      1023: {
        slidesPerView: 1.5,
        centeredSlides: false,
      },
      639: {
        slidesPerView: 1.2,
        spaceBetween: 32,
      },
    },
  });
}

export function membershipsSlider(sliderContainer) {
  return new Swiper(sliderContainer.querySelector('[data-role="slider-container"]'), {
    ...sliderOptions,
    slidesPerView: 3,
    spaceBetween: 80,
    effect: 'slide',
    breakpoints: {
      1200: {
        slidesPerView: 2.3,
        spaceBetween: 40,
        centeredSlides: false,
      },
      767: {
        slidesPerView: 1.3,
        spaceBetween: 32,
      },
    },
  });
}
