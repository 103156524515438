// constants
import { DOCUMENT_ELEMENT, KEY_CODES, SELECTORS } from '@/scripts/constants';

// other
import { toggleScrollbar } from '../../scripts/utils/toggleScrollbar';

// assets
import './hamburgerMenu.scss';

export function menuToggle() {
  const menuButton = document.querySelector('[data-role="menu-button"]');
  const offeringsContent = document.querySelector('[data-offerings-list-content]');
  const objectivesContent = document.querySelector('[data-objectives-list-content]');
  let timeout = null;

  const toggle = (allow) => {
    DOCUMENT_ELEMENT.classList.toggle(SELECTORS.menu_opened, allow);
    offeringsContent.classList.remove('is-active');
    objectivesContent.classList.remove('is-active');
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      toggleScrollbar(allow);
      DOCUMENT_ELEMENT.classList.toggle(SELECTORS.has_modal, allow);
    }, allow ? 0 : 750);
  };

  const isActive = () => DOCUMENT_ELEMENT.classList.contains(SELECTORS.menu_opened);

  const setupEvents = () => {
    menuButton.addEventListener('click', (e) => {
      e.preventDefault();
      toggle(!isActive());
    });
    document.addEventListener('keyup', (e) => {
      if (e.keyCode === KEY_CODES.esc && isActive()) {
        toggle(false);
      }
    });
  };

  if (menuButton) {
    setupEvents();
  }

  const offeringsButton = document.querySelector('[data-offerings-list]');
  const objectivesButton = document.querySelector('[data-objectives-list]');

  if (offeringsButton) {
    offeringsButton.addEventListener('click', (event) => {
      event.preventDefault();
      offeringsContent.classList.add('is-active');
      const closeSubList = offeringsContent.querySelector('[data-close-sub-list]');
      closeSubList.addEventListener('click', () => {
        offeringsContent.classList.remove('is-active');
      });
    });
  }

  if (objectivesButton) {
    objectivesButton.addEventListener('click', (event) => {
      event.preventDefault();
      objectivesContent.classList.add('is-active');
      const closeSubList = objectivesContent.querySelector('[data-close-sub-list]');
      closeSubList.addEventListener('click', () => {
        objectivesContent.classList.remove('is-active');
      });
    });
  }
}
