import { toggleScrollbar } from '@/scripts/utils/toggleScrollbar';
import './modals.scss';

$('[data-role="modal-trigger"]').fancybox({
  beforeShow() {
    console.log('open');
    toggleScrollbar(true);
  },
  afterClose() {
    console.log('close');
    toggleScrollbar(false);
  },
});
