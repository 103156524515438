// node-modules
import ScrollMagic from 'scrollmagic';
import { TweenLite } from 'gsap/TweenLite';

// constants
import { ROOT_CLASS, SELECTORS } from '../../scripts/constants';

// other
import { controller } from '../../scripts/utils/scrollmagic';

// assets
import './scrollToTop.scss';

(function scrollToTop() {
  const scrollButton = document.createElement('div');
  const content = document.querySelector('[data-role="content"]');
  if (!content || content.classList.contains('no-scroll-top')) return false;

  scrollButton.className = `${ROOT_CLASS} scroll-to-top`;
  scrollButton.dataset.role = 'scroll-to-top';
  document.body.appendChild(scrollButton);
  scrollButton.addEventListener('click', () => {
    TweenLite.to(window, 0.8, { scrollTo: { y: 0, autoKill: false } });
  });

  return new ScrollMagic.Scene({
    triggerElement: content,
    triggerHook: 'onLeave',
    offset: 400,
  })
    .setClassToggle(scrollButton, SELECTORS.active)
    // .addIndicators({ name: 'Scroll to top' })
    .addTo(controller);
}());
